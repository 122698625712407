function sliders() {
    (function($) {

        // Main Slider

        $('#main-slider .slider .container-bigger').show();

        function animateCurrentSlide() {

            $('#main-slider .header, #main-slider .btn, #main-slider .image').removeClass( 'animated fadeIn fadeInDown fadeInRight delay-1s slow');

            var currentSlide = this.innerElements[this.currentSlide];

            var header = $(currentSlide).find('.header');
            header.addClass('animated fadeInDown slow');

            var btn = $(currentSlide).find('.btn');
            btn.addClass('animated fadeIn delay-1s slow');

            var image = $(currentSlide).find('.image');
            image.addClass('animated fadeInRight slow');
        }

        const mainSlider = ( $('#main-slider .slider').length ?
        new Siema({
            selector: '#main-slider .slider',
            loop: true,
            duration: 600,
            easing: 'ease-in-out',
            onChange: animateCurrentSlide,
            //onInit: animateCurrentSlide,
        }) :
        ''
    );

    var mainSLiderTimer;

    function mainSliderAutoLoop() {
        mainSLiderTimer = setInterval( function(){mainSlider.next();}, 5000);
    }
    mainSliderAutoLoop();

    $('#main-slider .prev').click( function(e) {
        e.preventDefault();
        mainSlider.prev();
        clearInterval( mainSLiderTimer );
        mainSliderAutoLoop();
    });
    $('#main-slider .next').click( function(e) {
        e.preventDefault();
        mainSlider.next();
        clearInterval( mainSLiderTimer );
        mainSliderAutoLoop();
    });



    // News Slider

    const newsSlider = ( $('#news .slider').length ?
    new Siema({
        selector: '#news .slider',
        loop: true
    }) :
    ''
);

var newsSLiderTimer;

function newsSliderAutoLoop() {
    newsSLiderTimer = setInterval( function(){newsSlider.next();}, 5000);
}

if (newsSlider) {
    newsSliderAutoLoop();
}


$('#news .prev').click( function(e) {
    e.preventDefault();
    newsSlider.prev();
    clearInterval( newsSLiderTimer );
    newsSliderAutoLoop();
});
$('#news .next').click( function(e) {
    e.preventDefault();
    newsSlider.next();
    clearInterval( newsSLiderTimer );
    newsSliderAutoLoop();
});
})(jQuery); // Fully reference jQuery after this point.
}

function offCanvas() {
    (function($) {

        var mobileMenu = $('#mobile-menu');

        var searchForm = $('header.banner .search-form');
        var langNav = $('nav.nav-languages');
        var menuNav = $('nav.nav-primary');

        mobileMenu.find('.selector').click( function(e) {
            e.preventDefault();
        });

        mobileMenu.find('.selector.menu, .selector.menu i').click( function(e) {
            e.stopPropagation();
            menuNav.toggle();
            langNav.hide();
            searchForm.hide();
        });

        mobileMenu.find('.selector.lang, .selector.lang i').click( function(e) {
            e.stopPropagation();
            langNav.toggle();
            menuNav.hide();
            searchForm.hide();
        });

        mobileMenu.find('.selector.search, .selector.search i').click( function(e) {
            e.stopPropagation();
            searchForm.toggle();
            menuNav.hide();
            langNav.hide();
        });


    })(jQuery); // Fully reference jQuery after this point.

}

function term_ajax_get(termID, termSlug, termName) {
    var ajaxurl = DOCUMENTATION.ajax_url;
    jQuery.ajax({
        type: 'POST',
        url: ajaxurl,
        data: {"action": "load-docs", term: termID },
        beforeSend: function() {
            jQuery('#documentation-content').empty();
            jQuery('#productos-loading').removeClass('d-none');
        },
        complete: function() {
            jQuery('#productos-loading').addClass('d-none');
        },
        success: function(response) {
            jQuery('#documentation-content').html(response);

            const url = new URL(window.location);
            console.log(url)
            url.searchParams.set('term', termSlug);
            window.history.pushState({}, '', url);

            return false;
        }
    });
}

function docGetSelection() {
    (function($) {
        $('.ajax-producto').on('click', function(e) {
            e.preventDefault();
            var termID = $(this).data('id');
            var termSlug = $(this).data('slug');
            var termName = $(this).data('name');
            jQuery('#filters a').removeClass('active');
            $( this ).addClass('active');
            term_ajax_get(termID, termSlug, termName);
        });
    })(jQuery);
}

function imgGallery() {
    (function($) {

        var lightbox = $('.images a').simpleLightbox();

        $('#open-gallery').click( function(e) {
            e.preventDefault();
            lightbox.open();
        });

    })(jQuery);
}

function lazyLoadInit() {
    (function($) {

        const observer = lozad('.lazy');
        observer.observe();

    })(jQuery);
}

function disabledLink() {
    (function($) {
        $('a.disabled').click(function(e) {
            e.preventDefault();
        });
    })(jQuery);
}
